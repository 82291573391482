import TextFieldComponent from "./TextFieldComponent";
import {Button, CircularProgress, FormControl, FormLabel, IconButton, Stack, Typography} from "@mui/material";
import React, {useRef} from "react";
import TextFieldMemo from "./TextFieldMemo";
import RadiosComponent from "./RadiosComponent";
import CheckboxesComponent from "./CheckboxesComponent";
import NumberComponent from "./NumberComponent";
import DropdownComponent from "./DropdownComponent";
import {v4 as uuidv4} from "uuid";
import {FILE_TYPES} from "../fileConstants";
import DateComponent from "./DateComponent";
import ChildComponent from "./ChildComponent";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import axios from "axios";
import {API_URL} from "../App";
import {checkAuthError} from "../redux/reducers/authReducer";
import {openMessage} from "../redux/reducers/messageReducer";
import {useDispatch} from "react-redux";
import PopupState, {bindPopover, bindTrigger, anchorRef} from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import {useTranslation} from "i18nano";

const grid = 2;
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "lightgreen" : "none",
    width: "100%",
    cursor: isDragging ? "grab" : "pointer",
    ...draggableStyle
});

const getListStyle = () => ({
    padding: grid,
    width: '100%'
});
const mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([/])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);//eslint-disable-line
    return check;
};

const CommonComponent = React.memo(({
                                        item,
                                        value,
                                        order = null,
                                        groupId = null,
                                        isLocked = false,
                                        handleChangeText,
                                        handleMultipleText,
                                        handleAddNewTextField,
                                        handleClickRemoveTextField,
                                        handleChangeRadio,
                                        handleChangeCheckbox,
                                        handleChangeDate,
                                        dragStartFileHandler,
                                        dragLeaveFileHandler,
                                        dropFileHandler,
                                        dataSendFile,
                                        onDragEnd,
                                        handleChangeRadioWithOther,
                                        handleChangeRadioWithOtherChangeText,
                                        handleChangeCheckboxWithOther,
                                        handleChangeCheckboxWithOtherChangeText,
                                        statusFile = "none",
                                        updateStatusFiles
                                    }) => {
        const dispatch = useDispatch();
        const t = useTranslation();
        const ref = useRef();
        const viewDocument = (id) => {
            const token = localStorage.getItem("token");
            axios.get(`${API_URL}/attachment/${id}/download`, {
                headers: {"authorization": `Bearer ${token}`}
            })
                .then(response => {
                    if (response.data.success) {
                        if (!mobileAndTabletCheck()) {
                            window.open(response.data.data.downloadUrl, '_blank');
                        } else {
                            //window.open(response.data.data.downloadUrl, "Download");
                            const filePath = response.data.data.downloadUrl;
                            let link = document.createElement('a');
                            link.href = filePath;
                            link.download = true;
                            link.click();
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        dispatch(checkAuthError());
                        dispatch(openMessage(t("You don't have access to this file"), 'error'))
                    } else {
                        dispatch(openMessage(t("The request failed"), 'error'))
                    }
                })
        }

        if (!value) return null;

        if (item.questionType === 1) {
            if (item.allowMultiple === false) {
                return (
                    <TextFieldComponent
                        editable={item.editable}
                        required={item.required}
                        displayText={item.displayText}
                        status={value.status}
                        errorText={value.errorText}
                        value={value.value}
                        onChange={e => handleChangeText(e, item, value.id, order, groupId)}
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            } else {
                return (
                    <FormControl disabled={!item.editable} required={item.required}
                                 error={value.array.some(a => a.errorText !== "")}>
                        <FormLabel>{item.displayText}</FormLabel>
                        {
                            value.array.sort((a, b) => a.order - b.order).map((val, index) => {
                                return (
                                    <React.Fragment key={groupId ? `${groupId}${order}${item.id}${val.id}` : `${val.id}`}>
                                        <TextFieldMemo
                                            style={index !== 0 ? {marginTop: 5} : {}}
                                            disabled={!item.editable}
                                            required={item.required}
                                            value={val.value}
                                            status={val.status}
                                            errorText={val.errorText}
                                            onChange={e => handleMultipleText(e, item, val.id, order, groupId, val.order)}
                                            onClick={() => handleClickRemoveTextField(item.id, val.id, order, groupId)}
                                            onFocus={(e) => {
                                                if (value.status === "error") {
                                                    handleChangeText(e, item, value.id, order, groupId)
                                                }
                                            }
                                            }
                                        />

                                    </React.Fragment>
                                )
                            })
                        }
                        <Button disabled={!item.editable || isLocked} sx={{width: {xs: "100%", sm: 100}, marginTop: "5px"}}
                                onClick={() => handleAddNewTextField(item.id, order, groupId)} fullWidth={false}
                                variant="contained">{t("Add")}</Button>
                    </FormControl>
                )
            }
        } else if (item.questionType === 2) {
            if (item.other === false) {
                return (
                    <RadiosComponent
                        item={item}
                        status={value.status}
                        value={value.value}
                        errorText={value.errorText}
                        order={order} groupId={groupId}
                        valueId={value.id}
                        onChange={e => handleChangeRadio(e, item, value.id, order, groupId)}
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            } else {
                return (
                    <RadiosComponent
                        ey={groupId ? `${groupId}${order}${item.id}` : `${item.id}`}
                        item={item}
                        status={value.status}
                        value={value.value}
                        errorText={value.errorText}
                        order={order} groupId={groupId}
                        valueId={value.id}
                        onChange={e => handleChangeRadioWithOther(e, item, value.id, order, groupId)}
                        onChangeText={e => handleChangeRadioWithOtherChangeText(e, item, value.id, order, groupId)}
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            }
        } else if (item.questionType === 3) {
            if (item.other === false) {
                return (
                    <CheckboxesComponent
                        item={item}
                        status={value.status}
                        value={value.value}
                        errorText={value.errorText}
                        order={order} groupId={groupId}
                        valueId={value.id}
                        onChange={e => handleChangeCheckbox(e, item, value.id, order, groupId)}
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            } else {
                return (
                    <CheckboxesComponent
                        item={item}
                        status={value.status}
                        value={value.value}
                        errorText={value.errorText}
                        order={order} groupId={groupId}
                        valueId={value.id}
                        onChange={e => handleChangeCheckboxWithOther(e, item, value.id, order, groupId)}
                        onChangeText={e => handleChangeCheckboxWithOtherChangeText(e, item, value.id, order, groupId)}
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            }
        } else if (item.questionType === 4) {
            if (item.allowMultiple === false) {
                return (
                    <NumberComponent
                        item={item}
                        status={value.status}
                        value={value.value}
                        errorText={value.errorText}
                        onChange={e => {
                            const replacementString = e.target.value.replace(/\D/g, '');
                            if (replacementString === value.value) {
                                e.preventDefault();
                                return;
                            }
                            e.target.value = replacementString;
                            handleChangeText(e, item, value.id, order, groupId)
                        }
                        }
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            } else {
                return (
                    <FormControl disabled={!item.editable} required={item.required}>
                        <FormLabel>{item.displayText}</FormLabel>
                        {
                            value.array.sort((a, b) => a.order - b.order).map((val, index) => {
                                return (
                                    <React.Fragment key={groupId ? `${groupId}${order}${item.id}${val.id}` : `${val.id}`}>
                                        <TextFieldMemo
                                            style={index !== 0 ? {marginTop: 5} : {}}
                                            disabled={!item.editable}
                                            required={item.required}
                                            value={val.value}
                                            errorText={val.errorText}
                                            status={val.status}
                                            type={"number"}
                                            onChange={e => {
                                                const replacementString = e.target.value.replace(/\D/g, '');
                                                if (replacementString === val.value) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                e.target.value = replacementString;
                                                handleMultipleText(e, item, val.id, order, groupId, val.order)
                                            }}
                                            onClick={e => handleClickRemoveTextField(item.id, val.id, order, groupId)}
                                            onFocus={(e) => {
                                                if (value.status === "error") {
                                                    handleChangeText(e, item, value.id, order, groupId)
                                                }
                                            }
                                            }
                                        />

                                    </React.Fragment>
                                )
                            })
                        }
                        <Button disabled={!item.editable || isLocked} sx={{width: {xs: "100%", sm: 100}, marginTop: "5px"}}
                                onClick={() => handleAddNewTextField(item.id, order, groupId)} fullWidth={false}
                                variant="contained">{t("Add")}</Button>
                    </FormControl>
                )
            }
        } else if (item.questionType === 5) {
            if (item.other === false) {
                return (
                    <DropdownComponent
                        item={item}
                        status={value.status}
                        value={value.value}
                        errorText={value.errorText}
                        onChange={e => handleChangeRadio(e, item, value.id, order, groupId)}
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            } else {
                return (
                    <DropdownComponent
                        item={item}
                        status={value.status}
                        value={value.value}
                        errorText={value.errorText}
                        onChange={e => handleChangeRadioWithOther(e, item, value.id, order, groupId)}
                        onChangeText={e => handleChangeRadioWithOtherChangeText(e, item, value.id, order, groupId)}
                        onFocus={(e) => {
                            if (value.status === "error") {
                                handleChangeText(e, item, value.id, order, groupId)
                            }
                        }
                        }
                    />
                )
            }
        } else if (item.questionType === 6) {
            const idLabelUpload = uuidv4();
            return (
                <React.Fragment>
                    <FormLabel
                        disabled={!item.editable} required={item.required}
                        error={value.error}
                    >{item.displayText}</FormLabel>
                    <label
                        style={{marginTop: 0}}
                        onDragStart={e => {
                            if (statusFile === "loading") return;
                            dragStartFileHandler(e, item.id, order, groupId)
                        }}
                        onDragLeave={e => {
                            if (statusFile === "loading") return;
                            dragLeaveFileHandler(e, item.id, order, groupId)
                        }}
                        onDragOver={e => {
                            if (statusFile === "loading") return;
                            dragStartFileHandler(e, item.id, order, groupId)
                        }}
                        onDrop={e => {
                            dropFileHandler(e, item, value.array.length === 0 ? uuidv4() : value.array[0].id, groupId, order);
                        }}
                        htmlFor={idLabelUpload}
                        onChange={e => {
                            dropFileHandler(e, item, value.array.length === 0 ? uuidv4() : value.array[0].id, groupId, order);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                document.getElementById(idLabelUpload).click();
                            }
                        }}
                        onBlur={() => {
                            if (statusFile === "focus") {
                                updateStatusFiles("none", item.id, order, groupId)
                            }
                        }}


                    >
                        <input disabled={!item.editable || statusFile === "loading"}
                               style={{display: "none"}} sx={{display: "none"}}
                               id={idLabelUpload} multiple type="file"
                               accept={FILE_TYPES.join(", ")}

                        />

                        <Button disabled={!item.editable || statusFile === "loading"} fullWidth
                                sx={{height: 150}} variant="outlined"
                                style={{textAlign: "center"}}
                                component="span"
                                color={statusFile === "drag" || statusFile === "focus" ? "success" : value.error ? "error" : "primary"}
                                onFocusVisible={() => {
                                    updateStatusFiles("focus", item.id, order, groupId)
                                }}
                                ref={ref}
                        >
                            {
                                statusFile === "drag" ? t("Drop files") :
                                    statusFile === "focus" ? t("Press enter for choose files") :
                                        statusFile === "loading" ? <CircularProgress/> :
                                            value.error ? t("This field is required") : t("Drag your files or click here")
                            }
                        </Button>
                    </label>
                    {
                        value && value.array.length > 0 ?
                            <Stack direction={"row"}
                                   sx={{width: {md: "50%", sm: "100%"}, margin: "10px 0", flexWrap: "wrap"}}>
                                {
                                    <DragDropContext onDragEnd={r => onDragEnd(r, item, value.array[0].id, groupId, order)}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {value.array.map(
                                                        (file, index) => (
                                                            <Draggable key={file.attachmentId}
                                                                       draggableId={`${file.attachmentId}`} index={index}>
                                                                {(provided, snapshot) => {
                                                                    return <PopupState variant="popover"
                                                                                       popupId={`${file.attachmentId}`}>
                                                                        {(popupState) => {
                                                                            return (
                                                                                <div
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === "Delete") {
                                                                                            popupState.open();
                                                                                        }
                                                                                    }}
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getItemStyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}
                                                                                >
                                                                                    <div ref={anchorRef(popupState)}
                                                                                         style={{
                                                                                             display: "flex",
                                                                                             alignItems: "center",
                                                                                             justifyContent: "space-between"
                                                                                         }}
                                                                                         className={mobileAndTabletCheck() ? "buttonDocumentDivMobile" : "buttonDocumentDiv"}>
                                                                                                <span
                                                                                                    onClick={() => viewDocument(file.attachmentId)}
                                                                                                    className={"buttonDocument"}>
                                                                                                    {`${index + 1}. ${file.value}`}
                                                                                                </span>
                                                                                        <span>
                                                                                                    <IconButton {...bindTrigger(popupState)}
                                                                                                                aria-label="delete"
                                                                                                                size="small">
                                                                                                      <DeleteIcon
                                                                                                          fontSize="small"/>
                                                                                                    </IconButton>
                                                                                                    <Popover
                                                                                                        {...bindPopover(popupState)}
                                                                                                        anchorOrigin={{
                                                                                                            vertical: 'bottom',
                                                                                                            horizontal: 'center',
                                                                                                        }}
                                                                                                        transformOrigin={{
                                                                                                            vertical: 'top',
                                                                                                            horizontal: 'center',
                                                                                                        }}
                                                                                                        onKeyDown={e => {
                                                                                                            if (e.key === "Enter") {
                                                                                                                dataSendFile(file.attachmentId, item, file.id, groupId, order, true);
                                                                                                                popupState.close();
                                                                                                            } else {
                                                                                                                popupState.close();
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            sx={{p: 2}}>{t("Do you really want to delete this file")}?</Typography>
                                                                                                        <div style={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "flex-end",
                                                                                                            paddingBottom: "5px",
                                                                                                            marginRight: "5px"
                                                                                                        }}>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                onClick={() => {
                                                                                                                    dataSendFile(file.attachmentId, item, file.id, groupId, order, true);
                                                                                                                    popupState.close();
                                                                                                                }}>
                                                                                                                {t("Yes")}
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                color={"error"}
                                                                                                                onClick={() => {
                                                                                                                    popupState.close();
                                                                                                                }}
                                                                                                                sx={{marginLeft: "5px"}}>
                                                                                                                {t("No")}
                                                                                                            </Button>
                                                                                                        </div>

                                                                                                    </Popover>
                                                                                                    <DragIndicatorIcon
                                                                                                        fontSize="small"
                                                                                                        style={{marginRight: "5px"}}/>
                                                                                                </span>
                                                                                    </div>
                                                                                </div>)
                                                                        }
                                                                        }
                                                                    </PopupState>
                                                                }
                                                                }
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                }
                            </Stack> : null
                    }
                </React.Fragment>
            )
        } else if (item.questionType === 7) {

            return (
                <DateComponent
                    item={item}
                    status={value.status}
                    value={value.value}
                    errorText={value.errorText}
                    onChange={e => handleChangeDate(e, item, value.id, order, groupId)}
                    onFocus={(e) => {
                        if (value.status === "error") {
                            handleChangeText(e, item, value.id, order, groupId)
                        }
                    }
                    }
                />
            )
        } else if (item.questionType === 8) {
            return (
                <ChildComponent
                    item={item}
                    value={value}
                    onChange={handleMultipleText}
                    onClickAdd={handleAddNewTextField}
                    onClickRemove={handleClickRemoveTextField}
                    onFocus={(e) => {
                        if (value.status === "error") {
                            handleChangeText(e, item, value.id, order, groupId)
                        }
                    }
                    }
                />
            )

        }
        /*else if (item.questionType === 8) {
            return (value ?
                    <FormControl disabled={!item.editable} required={item.required}>
                        <FormLabel>{item.displayText}</FormLabel>
                        {
                            value.map(val => {
                                return (
                                    <React.Fragment key={val.id}>
                                        <TextFieldMemo disabled={!item.editable} required={item.required} value={val.value}
                                                       onChange={e => handleMultipleText(e, item, val.id, order, groupId)}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position="end">
                                                                   <IconButton
                                                                       aria-label="delete text field"
                                                                       onClick={() => handleClickRemoveTextField(item.id, val.id, order, groupId)}
                                                                       onMouseDown={e => e.preventDefault()}
                                                                       edge="end"
                                                                   >
                                                                       <DeleteIcon />
                                                                   </IconButton>
                                                               </InputAdornment>
                                                           ),
                                                       }}

                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                        <Button disabled={!item.editable} sx={{ width: { xs: "100%", sm: 100 }, marginTop: "5px" }} onClick={() => handleAddNewTextField(item.id, order, groupId)} fullWidth={false} variant="contained">Add</Button>
                    </FormControl>
                    : null
            )

        }*/
        else if (item.questionType === 9) {
            return (
                <DropdownComponent
                    item={item}
                    status={value.status}
                    value={value.value}
                    errorText={value.errorText}
                    onChange={e => handleChangeRadio(e, item, value.id, order, groupId)}
                    onFocus={(e) => {
                        if (value.status === "error") {
                            handleChangeText(e, item, value.id, order, groupId)
                        }
                    }
                    }
                />
            )
        }

    }, (prev, next) =>
        prev.value === next.value &&
        prev.errorText === next.errorText &&
        prev.status === next.status &&
        prev.statusFile === next.statusFile &&
        prev.item.displayText === next.item.displayText
)

export default CommonComponent;