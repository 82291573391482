import React, {useEffect, useState} from "react";
import LoopIcon from "@mui/icons-material/Loop";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {Tooltip} from "@mui/material";
import {useTranslation} from "i18nano";

const StatusComponent = ({status, elseComponent = null}) => {
    const [internalStatus, setInternalStatus] = useState(false);
    const t = useTranslation();

    useEffect(() => {
        let timer;
        if (status === "updated") {

            timer = setTimeout(() => {
                setInternalStatus(true)
            }, 5000)
        } else {
            setInternalStatus(false)
        }

        return () => {
            clearTimeout(timer);
        }
    }, [status])

    return status === "updating" ?
        <Tooltip title={t("Saving")} arrow><LoopIcon color="primary" className={"rotateIcon"}/></Tooltip>
        : status === "updated" && !internalStatus ?
            <Tooltip title={t("Saved")} arrow><CheckCircleOutlineIcon color="success"/></Tooltip>
            : status === "error" && !internalStatus ?
                <Tooltip title={t("Error")} arrow><ErrorOutlineIcon color={"error"}/></Tooltip> : elseComponent
}

export default StatusComponent;