import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import axios from "axios";
import {API_URL} from "../App";
import {checkAuthError} from "../redux/reducers/authReducer";
import {useDispatch} from "react-redux";

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon/>} {...props}
        className={"accordionHeightStatic"} sx={{backgroundColor: "#036aaa", color: "white"}}
    />
))(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    color: "white",
}));

const PresetComponent = ({children, title, id, isAccordion, isCollapsed, presetId, partyId, error}) =>{
    const [expanded, setExpanded] = useState(!isCollapsed);

    const dispatch = useDispatch();

    const onExpanded = () =>{

        const token = localStorage.getItem("token");
        if (!token) dispatch(checkAuthError());

        axios.post(`${API_URL}/party/${partyId}/preset/${presetId}/collapse`, { value: expanded},
            {
                headers: {"authorization": `Bearer ${token}`}
            });
        setExpanded(!expanded);

    }

    if(isAccordion){
        return(
            <>
                <Accordion id={id} expanded={expanded} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:"white"}}/>} sx={{minHeight: "48px !important;"}}
                                      onClick={onExpanded} >
                        <Typography variant="h6" sx={{color: (error ? "red" : "white")}}>
                            {title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {children}
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }
    return children;
}

export default PresetComponent;