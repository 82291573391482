import axios from "axios"
import {changeCurrentApplicant, getParties, getUserSuccess, setStatus} from "../reducers/applicantsReducer";
import {checkAuthError} from "../reducers/authReducer";
import {API_URL} from "../../App";
import {openMessage} from "../reducers/messageReducer";

export const getUserThunk = () => {
    return dispatch => {
        const token = localStorage.getItem("token");
        console.log(token)
        if (!token) {
            dispatch(checkAuthError());
            dispatch(changeCurrentApplicant(-1));
            return;
        }

        axios.get(`${API_URL}/user`, {
            headers: {"authorization": `Bearer ${token}`}
        })
            .then(response => {
                dispatch(getUserSuccess(response.data.data));
            })
            .catch(error => {
                try {
                    dispatch(checkAuthError());
                    dispatch(changeCurrentApplicant(-1));
                } catch (e) {
                    console.log(e);
                }
            })
    }
}

export const getPartiesThunk = (id) => {
    return dispatch => {

        const token = localStorage.getItem("token");
        if (!token) {
            dispatch(checkAuthError());
            dispatch(changeCurrentApplicant(-1));
            return;
        }

        axios.get(`${API_URL}/applicant/${id}`, {
            headers: {"authorization": `Bearer ${token}`}
        })
            .then(response => {
                dispatch(setStatus("200"))
                dispatch(getParties({
                    parties: response.data.data.parties,
                    applicantId: id,
                    isLocked: response.data.data.isLocked
                }));
            })
            .catch(error => {
                try {
                    if (error.response.status === 401) {
                        dispatch(changeCurrentApplicant(-1));
                        dispatch(checkAuthError());
                        dispatch(openMessage("You are not authorized", 'error'))
                    } else if (error.response.status === 403) {
                        dispatch(setStatus("403"))
                        //dispatch(openMessage("You don't have access to this client", 'error'))
                    } else {
                        dispatch(openMessage("The request failed", 'error'))
                    }
                } catch (e) {
                    console.log(e);
                }
            })
    }
}