import {Container, Typography, InputAdornment, TextField, IconButton, Stack, Alert} from "@mui/material"
import AccountCircle from '@mui/icons-material/AccountCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import {LoadingButton} from "@mui/lab";
import {useEffect, useState, createRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {authUser} from "../redux/asyncAction/auth"
import {NavLink, useNavigate} from "react-router-dom";
import {setLoadingButton} from "../redux/reducers/authReducer";
import {useTranslation} from 'i18nano';
import * as React from "react";


const AuthPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    const emailRef = createRef();
    const passwordRef = createRef();

    const t = useTranslation();

    const navigate = useNavigate();

    const isAuth = useSelector(state => state.authReducer.isAuth)
    const loading = useSelector(state => state.authReducer.loadingButton)
    const errorData = useSelector(state => state.authReducer.errorData);
    const dispatch = useDispatch();

    const [errors, setErrors] = useState([]);

    const handleClickShowPassword = () => {
        setShowPassword(prev => !prev);
    };
    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeEmail = e => {
        setEmail(e.target.value);
    }

    const handleClickButtonLogIn = async () => {
        dispatch(setLoadingButton(true));
        dispatch(authUser(email, password));
    }

    useEffect(() => {
        dispatch(setLoadingButton(false));
    }, [isAuth])

    let firstRender = false;
    const [shrink, setShrink] = useState(false);
    const [shrinkPassword, setShrinkPassword] = useState(false);
    const [autoFill, setAutoFill] = useState(false);
    const animationStart = (e) => {
        switch (e.animationName) {
            case "mui-auto-fill":
                setShrink(true);
                setShrinkPassword(true);
                setAutoFill(true);
        }
    };

    useEffect(() => {
        firstRender = true;
        setErrors([]);
        console.log("set serrorrs")
        emailRef.current.addEventListener('animationstart', animationStart);
        passwordRef.current.addEventListener('animationstart', animationStart);
    }, []);

    useEffect(() => {
        if (!firstRender) {
            if (errorData && errorData.errors) {
                if (errorData.errors.includes("AUTHENTICATOR_IS_NOT_CONNECTED")) {
                    navigate("/authenticator")
                } else if (errorData.errors.includes("TWO_FACTOR_CODE_REQUIRED")) {
                    if (errorData.data.availableTwoFactorProviders.includes(0)) {
                        navigate("/two-factor-authentication")
                    } else if (errorData.data.availableTwoFactorProviders.includes(1)) {
                        navigate("/two-factor-authentication/phone")
                    }
                } else if (errorData.errors.includes("PHONE_IS_NOT_CONNECTED")) {
                    navigate("/phoneAuthenticator")
                }
                setErrors(errorData?.errors)
            }
        }
    }, [errorData])

    return (
        <Container>
            <Stack
                spacing={2}
                sx={{
                    maxWidth: 400,
                    margin: "auto",
                    marginTop: 5
                }}
                justifyContent={'center'}
            >
                <Typography variant="h4" align={'center'}>
                    {t("Log In Title")}
                </Typography>

                <TextField
                    disabled={loading}
                    fullWidth
                    id="email"
                    value={email}
                    onChange={handleChangeEmail}
                    inputProps={{inputMode: "email"}}
                    onKeyPress={e => {
                        if (e.code === "Enter") {
                            if (email === "" || password === "") return;
                            handleClickButtonLogIn();
                        }
                    }}
                    onBlur={() => email ? setShrink(true) : setShrink(false)}
                    onFocus={() => setShrink(true)}
                    InputLabelProps={{
                        shrink: shrink,
                    }}
                    ref={emailRef}

                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={true}
                                    edge="end"
                                    className={"changeColorAccountCircle"}
                                >
                                    <AccountCircle edge="end"/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }
                    }
                    aria-describedby="outlined-email-helper-text"
                    label={t("Email")}
                    variant="outlined"
                />

                <TextField
                    disabled={loading}
                    fullWidth
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChangePassword}
                    onKeyPress={e => {
                        if (e.code === "Enter") {
                            if (email === "" || password === "") return;
                            handleClickButtonLogIn();
                        }
                    }}
                    onBlur={() => password ? setShrinkPassword(true) : setShrinkPassword(false)}
                    onFocus={() => setShrinkPassword(true)}
                    InputLabelProps={{
                        shrink: shrinkPassword,
                    }}
                    ref={passwordRef}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    label={t("Password")}
                    variant="outlined"
                />
                {errors?.map(e => <Alert severity="error">{e}</Alert>)}

                <LoadingButton disabled={(email === "" || password === "") && !autoFill} loading={loading} size={"large"}
                               loadingPosition="start" startIcon={<></>} variant="contained"
                               onClick={handleClickButtonLogIn}>{loading ? `${t("Logging in")}...` : t("Log In")}</LoadingButton>

                <Typography variant="body2" align={"center"} style={{fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'," +
                        "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'," +
                        "    sans-serif"}}>
                    By logging into CFlow<sup style={{fontSize: "0.5rem"}}>TM</sup>, you agree to our
                    <a style={{marginLeft:5, marginRight: 5}} href={'https://carolynleepllc.com/privacy-policy/'} target={"_blank"}>Privacy Policy</a>
                    and our
                    <a style={{marginLeft:5}} href={'https://carolynleepllc.app.box.com/s/oeaplmmrlp7ak24qpxzt84j4x3hm6wyp'} target={"_blank"}>Terms and Conditions</a>.
                </Typography>

                <NavLink to="/reset" style={{textAlign: "center"}}>
                    {t("Reset password")}
                </NavLink>
            </Stack>

        </Container>
    )
}

export default AuthPage;